@import "../../bootstrap/functions";
@import "../../bootstrap/variables";

.rc-slider-disabled .rc-slider-handle {
  border: solid 4px blue;
}

.rc-slider-disabled .rc-slider-dot.rc-slider-dot-active {
  border: solid 3px blue;
}

.rc-slider-disabled .rc-slider-dot {
  border: solid 3px gray;
}

.rc-slider-dot {
  bottom: -0.5px;
  margin-left: -4px;
  width: 6px;
  height: 6px;
}

.rc-slider-handle {
  margin-left: -5px;
  margin-top: -2px;
  width: 8px;
  height: 8px;
}

.rc-slider-disabled {
  background-color: transparent;
}

.slider-xl {
  height: 0.65rem;
  width: 5rem;
}
