.wizard-step-current {
  color: $white;
  background-color: $green;
  border: solid 1px scale-color($color: $green, $lightness: 25%);
}
.wizard-step-previous {
  color: $white;
  background-color: scale-color($color: $green, $lightness: 25%);
  border: solid 1px scale-color($color: $green, $lightness: 50%);
}

.pointer {
  cursor: pointer;
}

.force-address a {
  cursor: pointer;
  text-decoration: underline $info !important;
  color: $info !important;
}
