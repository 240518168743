/* Variables */

$color-primary: $green;
$color-light: $green-light;

$spacing: 30px;
$radius: 4px;

$date: 120px;
$dotborder: 4px;
$dot: 11px;
$line: 4px;

// $font-title: "Saira", sans-serif;
// $font-text: "Chivo", sans-serif;

/* Base */

.timeline-content {
  margin-top: $spacing;
  text-align: center;

  /* Timeline */

  .timeline-grid {
    border-left: $line solid $color-primary;
    border-bottom-right-radius: $radius;
    border-top-right-radius: $radius;
    // font-family: $font-text;
    margin: $spacing auto;
    padding: $spacing;
    letter-spacing: 0.5px;
    position: relative;
    line-height: 1.4em;
    font-size: 1.03em;
    list-style: none;
    text-align: left;
    max-width: 30%;
    display: grid;

    &:before {
      box-sizing: content-box;
    }

    h5 {
      // font-family: $font-title;
      letter-spacing: 1.5px;
      font-weight: 100;
      font-size: 1.4em;
    }

    .event {
      border-bottom: 1px dashed rgba($color-light, 0.1);
      padding-bottom: ($spacing * 0.5);
      margin-bottom: $spacing;
      min-width: 300px;
      position: relative;

      &:last-of-type {
        padding-bottom: 0;
        margin-bottom: 0;
        border: none;
      }

      &:before,
      &:after {
        position: absolute;
        display: block;
        top: 0;
      }

      &:before {
        left: (
            (($date * 0.6) + $spacing + $line + $dot + ($dotborder * 2)) * 1.5
          ) * -1;
        content: attr(data-date);
        text-align: right;
        font-weight: bold;
        font-size: 0.9em;
        min-width: $date;
        // font-family: $font-title;
      }

      &:after {
        box-shadow: 0 0 0 $dotborder rgba($color-primary, 0.9);
        left: ($spacing + $line + ($dot * 0.35)) * -1;
        background: lighten($color-light, 5%);
        border-radius: 50%;
        height: $dot;
        width: $dot;
        content: "";
        top: 5px;
      }
    }
  }
}
