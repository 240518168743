.applyZIndex {
  z-index: 10;
}
.select-container {
  position: relative;
  box-sizing: border-box;
  z-index: auto;
}
.select-box.disabled {
  background-color: #edf1f2;
  opacity: 1;
}
.select-box.is-invalid {
  border-color: #f05050;
}

.select-box:hover:not(.disabled) {
  border-color: #2684ff;
}
.select-box {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: #dde6e9;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
}

.input-container {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 2px 8px;
  -webkit-overflow-scrolling: touch;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  margin-left: 8px;
  min-height: 28px;
}

.select-value,
.select-value-only {
  color: hsl(0, 0%, 20%);
  margin-left: 28px;
  margin-right: 2px;
  max-width: calc(100% - 8px);
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  box-sizing: border-box;
}

.select-value-only {
  margin-left: 2px;
}

.select-content {
  display: contents;
  width: 100%;
}

.input-switcher {
  display: inline-block;
  width: inherit;
}
.input-switcher > input {
  box-sizing: content-box;
  width: 2px;
  background: 0px center;
  border: 0px;
  font-size: inherit;
  opacity: 1;
  outline: 0px;
  padding: 0px;
  color: inherit;
  width: inherit;
}

.input-switcher .switch {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  height: 0;
  overflow: scroll;
  white-space: pre;
  font-size: 14px;
  font-family: Source Sans Pro, sans-serif;
  font-weight: 400px;
  font-style: normal;
  letter-spacing: normal;
  text-transform: none;
}

.indicator-container {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  box-sizing: border-box;
}

span.input-separator {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  background-color: hsl(0, 0%, 80%);
  margin-bottom: 8px;
  margin-top: 8px;
  width: 1px;
  box-sizing: border-box;
}

.indicator-symbol {
  color: hsl(0, 0%, 80%);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px;
  -webkit-transition: color 150ms;
  transition: color 150ms;
  box-sizing: border-box;
}
.indicator-symbol:hover {
  color: hsl(0, 0%, 60%);
}
.indicator-symbol > svg {
  display: inline-block;
  fill: currentColor;
  line-height: 1;
  stroke: currentColor;
  stroke-width: 0;
}

.select-option {
  background-color: transparent;
  color: inherit;
  cursor: default;
  display: block;
  font-size: inherit;
  padding: 8px 12px;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  z-index: 2;
}
.select-option.hover {
  background-color: #deebff;
}
.select-option.selected {
  background-color: #2684ff;
  color: hsl(0, 0%, 100%);
}

.options-container {
  top: 100%;
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  margin-bottom: 8px;
  margin-top: 8px;
  position: absolute;
  width: 100%;
  z-index: 20;
  box-sizing: border-box;
}

.options-list {
  max-height: 300px;
  overflow-y: auto;
  padding-bottom: 4px;
  padding-top: 4px;
  position: relative;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  z-index: 1;
}

.options-empty {
  color: hsl(0, 0%, 60%);
  padding: 8px 12px;
  text-align: center;
  box-sizing: border-box;
}

span.assertive {
  z-index: 9999;
  border: 0;
  -webkit-clip: rect(1px, 1px, 1px, 1px);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  width: 1px;
  position: absolute;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
  background-color: red;
  color: blue;
}

p.as-label {
  margin-bottom: 0.5rem;
}
