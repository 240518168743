.min-height-150 {
  min-height: 150px;
}
.min-height-200 {
  min-height: 200px;
}
.min-height-300 {
  min-height: 300px;
}
.min-height-700 {
  min-height: 700px;
}
