/* ========================================================================
     Component: table-extras
 ========================================================================== */

// Add support to components inside tables
.dashed {
  text-decoration: underline dashed;
}

.table.compact {
  max-width: fit-content;
  > thead > tr > th {
    padding: 2px !important;
  }
  > tbody > tr > td {
    padding: 2px 3px 2px 3px !important;
  }
  > tfoot > tr > td {
    padding: 2px !important;
    border-top: 0 !important;
  }
}

.table-striped tfoot tr {
  background-color: #fafbfc;
}

.table,
.rdt_Table {
  > thead > tr > th,
  > .rdt_TableHead > .rdt_TableHeadRow > .rdt_TableCol {
    padding: 14px 8px;
    color: #888;
  }

  > tbody > tr > td,
  > .rdt_TableBody > .rdt_TableRow > .rdt_TableCell {
    vertical-align: middle;
    > .media {
      img {
        width: 36px;
        height: 36px;
        margin: 0 auto;
      }
    }
  }

  .checkbox {
    margin: 0 auto;
    width: 20px;
  }

  .progress {
    margin-bottom: 0;
  }

  .radial-bar {
    margin-bottom: 0;
    margin: 0 auto;
  }
}

.jqstooltip {
  box-sizing: content-box;
}

// Extended table demos

#table-ext-1 {
  th {
    &:nth-child(1) {
      width: 3%;
    }

    &:nth-child(2) {
      width: 5%;
    }

    &:nth-child(7) {
      width: 5%;
    }

    &:nth-child(9) {
      width: 5%;
    }
  }
}

#table-ext-2 {
  th {
    &:nth-child(1) {
      width: 5%;
    }

    &:nth-child(3) {
      width: 10%;
    }
  }
}

@media only screen and (min-width: 1450px) {
  .table .ellipsis-list,
  .rdt_TableCell .ellipsis-list {
    display: inline-flex;
    width: 100%;
    &:last-child {
      div {
        min-width: 20px;
      }
    }
  }

  .table .ellipsis,
  .rdt_TableCell .ellipsis {
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
}

.table .reference,
.rdt_TableCell .reference {
  max-width: 80px;
}
.table .content,
.rdt_TableCell .content {
  max-width: 100px;
}
.table .locality,
.rdt_TableCell .locality {
  max-width: 95px;
}
.table .name,
.rdt_TableCell .name {
  max-width: 100px;
}
.table .packages,
.rdt_TableCell .packages {
  max-width: 120px;
}

.table,
.rdt_Table {
  background-color: #f0f0f0;
  color: #423535;
}

@media screen and (max-width: 2040px) and (min-width: 1900px) {
  .priority-5 {
    display: none;
  }
}

@media screen and (max-width: 1900px) and (min-width: 1800px) {
  .priority-5 {
    display: none;
  }
  .priority-4 {
    display: none;
  }
}

@media screen and (max-width: 1800px) and (min-width: 1600px) {
  .priority-5 {
    display: none;
  }
  .priority-4 {
    display: none;
  }
  .priority-3 {
    display: none;
  }
}

@media screen and (max-width: 1600px) {
  .priority-5 {
    display: none;
  }
  .priority-4 {
    display: none;
  }
  .priority-3 {
    display: none;
  }
  .priority-2 {
    display: none;
  }
}

$table-column-border-color: solid 1px rgba(0, 0, 0, 0.07);

.table-column-bordered {
  > thead > tr > th {
    white-space: nowrap;
    border-top: $table-column-border-color;
    border-right: $table-column-border-color;
    border-bottom: $table-column-border-color;
    &:nth-child(1) {
      border-left: $table-column-border-color;
    }
  }
  > tbody > tr > td {
    white-space: nowrap;
    border-right: $table-column-border-color;
    &:nth-child(1) {
      border-left: $table-column-border-color;
    }
  }
  > tbody > tr {
    &:last-child {
      td {
        border-bottom: $table-column-border-color;
      }
    }
  }
}

td .inline-w160,
.rdt_TableCell .inline-w160 {
  display: inline-flex;
  flex-wrap: nowrap;
  width: 160px;
}

td .w-75,
.rdt_TableCell .w-75 {
  width: 75px;
}

.tab-content {
  padding: 10px 10px;
}

td .fa-chevron-down.collapsed {
  transform: rotate(-90deg);
}

td .fa-info-circle.collapsed {
  transform: perspective(1.5em);
}

.expanding-transition {
  transition: ".3s transform ease-in-out";
}

.panel td {
  padding: 0.35rem;
}
