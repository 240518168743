@keyframes fadeInKeyFrames {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes fadeInKeyFrames {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.flyout {
  -webkit-animation-name: fadeInKeyFrames;
  animation-name: fadeInKeyFrames;
  height: 100% !important;
  max-width: fit-content;

  .modal-header {
    border-radius: 0;
    align-items: center;
    padding: 1.2rem;
  }

  .modal-content {
    border-radius: 0;
    border: 0;
    -webkit-box-shadow: -12px 0 38px -14px rgba(0, 0, 0, 0.45);
    -moz-box-shadow: -12px 0 38px -14px rgba(0, 0, 0, 0.45);
    box-shadow: -12px 0 38px -14px rgba(0, 0, 0, 0.45);
    background-clip: padding-box;
    height: 100% !important;
    overflow-y: scroll;
  }

  .modal-backdrop {
    background-color: transparent;
  }

  .modal-button-container {
    margin: 0 auto;
    width: 90%;
    max-width: 500px;
  }

  .btn {
    border-radius: 0;
  }
}

.modal-dialog.flyout {
  margin: 0;
}

.flyout.animated {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
